button, input[type='submit']{
    width: 100%;
    border:none;
    border-radius: .2em;
    box-sizing: border-box;
    padding: 1.1em 1.1em 1em 1.1em;
    color: $themeBase;
    font-weight: 500;
    background: $theme3;
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: .1em;
    box-shadow: 0 .05em 0.25em rgba(0,0,0,0.1);
    @extend %trans;

    &:hover{
        background: $theme;
        box-shadow: 0 .75em 1.25em rgba(0,0,0,0.15);
    }
    &:active{
        background: $theme2;
        box-shadow: 0 .2em .3em rgba(0,0,0,0.1);
    }
    
}