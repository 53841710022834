#Menu{
    background: $theme;
    @extend %trans;
    width: 300px;
    
    .subHeader{
        position: relative;
        float: left;
        width: 100%;
        padding: .5em 1em;
        border-bottom: 2px solid rgba(#ffffff, 0.1);
        color: white;
    }
    .btExpand, .btAccount{
        position: relative;
        left: 0;
        width: 32px;
        height: 32px;
        fill:white;
        padding: 4px;
        @extend %trans;
        border-radius: 50%;
        opacity: .5;
        cursor: pointer;
        
        &:hover{
            opacity: 1;
            background: rgba($themeDark, 0.3);
        }
        &:active{
            background: rgba($themeDark, 0.5);
        }
    }
    
    .btAccount{
        float: right;
    }

    .menu{
        position: relative;
        float: right;
        height: 100%;
        padding: 1em 0;
        box-sizing: border-box;
        width: 100%;
    }

    .menu.compact{
        width: calc(100% - 64px);
    }
    
    
    .actions-sidemenu{
        position: relative;
        float: left;
        width: 64px;
        height: 100%;
        background: rgba(#ffffff, 0.02);
        display: none;
        
        .add, .search{
            position: relative;
            float: left;
            width: 36px;
            height: 36px;
            fill: white;
            padding: 5px;
            background: transparent;
            border-radius: 50%;
            margin: .25em 14px;
            border: 0px solid transparent;
            cursor: pointer;
            @extend %trans;
            
            &:hover{
                background: rgba(0,0,0,0.2);
            }
        }
        
        .user{
            position: relative;
            float: left;
            width: 38px;
            height: 38px;
            background: transparent;
            border-radius: 50%;
            margin: 1.5em 12px 1em 12px;
            border: 0px solid transparent;
            cursor: pointer;
            border:2px solid rgba(#ffffff, 0.2);
            @extend %trans;
            z-index: 1;
            &:hover{
                border:2px solid rgba(#ffffff, 0.1);
            }
        }
    }
    
    figure.logo{
        position: relative;
        float: left;
        width: 100%;
        display: flex;
        height: 50px;
        border-radius: .2em;
        margin: .75em 0 .25em 0;
        @extend %trans;
        img{
            position: relative;
            float: left;
            width: auto;
            height: auto;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
            @extend %trans;
        }
    }
    
    ul,li{
        position: relative;
        float: left;
        width: 100%;
    }

    .select-client{
        position: relative;
        float: right;
        width: calc(100% - 2em);
        margin: 1em;
    }
    
    li{
        margin: .25em 1em;
        width: calc(100% - 2em);
        box-sizing: border-box;
        color: $themeDark;
        font-size: .9rem;
        cursor: pointer;
        line-height: 1.5;
        box-sizing: border-box;
        a{
            position: relative;
            float: left;
            width: 100%;
            color: white;
            padding: .45em .8em;
            box-sizing: border-box;
            letter-spacing: 1px;
            background: rgba(#000000, 0);
            box-shadow: 0 0 0 rgba(0,0,0,0);
            @extend %trans;
            
            &:hover{
                p{color: rgba($color: #fff, $alpha: 0.8);}
                svg{fill: rgba($color: #fff, $alpha: 0.8);}
            }
            
            p{
                position: relative;
                float: right;
                width: calc(100% - 34px);
                line-height: 1.8;
                letter-spacing: .3px;
                color:rgba($color: #fff, $alpha: 0.5);
                @extend %trans;
            }
            
            svg{
                position: relative;
                float: left;
                width: 24px;
                height: 24px;
                fill: rgba($color: #fff, $alpha: 0.5);
                background: transparent;
                border: 0px solid transparent;
                cursor: pointer;
                @extend %trans;
            }
        }
    }
    
    li a.on{
        width: 100%;
        box-shadow: 0 0 0 rgba(0,0,0,0);
        p{color: white}
        svg{fill:white}
    }
    
    .user-menu{
        position: absolute;
        width: 100%;
        padding:1.5em 1em;
        box-sizing: border-box;
        background: $themeDark;
        box-shadow: 0 1em 1em rgba($themeDark,0.1);
        height: 100%;
        top: -100vh;
        overflow: hidden;
        opacity: 0;
        @extend %trans;

        .info{
            position: relative;
            float: left;
            width: 100%;
            margin-bottom: 2em;
        }

        .content{
            position: relative;
            float: right;
            width: calc(100% - 64px);
        }

        .select-client{
            position: relative;
            float: left;
            width: 100%;
        }
        
        p{
            position: relative;
            float: left;
            font-size: .82rem;
            color: white;
            width: 100%;
            line-height: 1.4;
            letter-spacing: .2px;
        }
        
        a{
            position: relative;
            float: left;
            width: 100%;
            color: #ffffff;
            padding: .45em .8em;
            box-sizing: border-box;
            letter-spacing: 1px;
            background: rgba(#000000, 0.2);
            border-radius: .5em;
            box-shadow: 0 0 0 rgba(0,0,0,0);
            margin: .5em 0;
            @extend %trans;

            
            &:hover{
                p{color: rgba($color: #fff, $alpha: 0.8);}
                svg{fill: rgba($color: #fff, $alpha: 0.8);}
            }
            
            p{
                position: relative;
                float: right;
                width: calc(100% - 34px);
                line-height: 1.8;
                letter-spacing: .3px;
                color:rgba($color: #fff, $alpha: 0.4);
                @extend %trans;
            }
            
            svg{
                position: relative;
                float: left;
                width: 24px;
                height: 24px;
                fill: rgba($color: #fff, $alpha: 0.4);
                background: transparent;
                border: 0px solid transparent;
                cursor: pointer;
                @extend %trans;
            }
        }
    }

    .user-menu.on{
        top: 0px;
        opacity: 1;
    }
    
}

#Menu.compact{
    width: 4em;
    padding: 0;
    min-width: 4em;
    background: $theme;

    figure.logo, .select-client, .btAccount{
        opacity: 0;
        transform:scale(0);
        height: 0;
        margin: 0;
    }


    .subHeader{
        border-bottom: none;
    }
    
    .btExpand{
        position: relative;
        float: left;
        margin: 1em calc(50% - 16px) 0;
        box-sizing: border-box;
        transform: rotate(180deg)
    }

    .menu{
        height: auto;
    }

    ul{
        position: relative;
        width: 100%;

        li{
            position: relative;
            float: left;
            margin: .25em 0;
            width: calc(100% - 0px);
            padding: .25em 0;
            a{
                padding: .4em 0;
                display: flex;
                svg{margin: auto}
            }
            p{display: none;}
        }
    }
}