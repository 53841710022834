#environmentsView {
    background: $themeBase;

    header.panel{
        position: relative;
        float: left;
        width: 100%;
        background: white;
        border-bottom: 1px solid rgba(0,0,0,.0975);
        padding: .5em 1em;
        box-sizing: border-box;
        display: flex;

        figure{
            position: relative;
            float: left;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border:1px solid rgba(0,0,0,.0975);
            display: flex;
            overflow: hidden;
            box-sizing: border-box;
            padding: .3em;

            img{
                margin: auto;
                width:100%;
            }

        }

        .text{
            margin: auto 1em;
            h1{ font-size: 1.25em; margin-bottom: .15em;}
            a{
                font-size: .9rem;
                &:hover{text-decoration: underline; }
            }
        }
    }
   
    .dashboard-cards{
        width: 100%;
        display: flex;
        flex-direction: column;
        width: calc(100% - 4em);
        box-sizing: border-box;
        padding: 1em;
        
        @include desktop{
            width: 100%;
            padding: 1em 0;
            flex-direction: row;
        }
    }

    .dahsboard-card{
        position: relative;
        float: left;
        border-radius: .4em;
        height: auto;
        overflow: hidden;
        margin: .5em auto;
        width: 100%;
        height:9em;
        background: white;
        border:1px solid rgba(0, 0, 0, 0.0975);
        box-shadow: 0 .33em 1em rgba($color: $themeDark, $alpha: 0.025);
        display: flex;
        flex-direction: column;
        @extend %trans;

      
        @include desktop{
            width: 28%;
        }

        .content{
            width: 100%;
            display: flex;
            flex-grow: 1;
        }
        
        legend.header-label{
            width: 100%;
            color:$themeDark2;
            top: 0;
            left: 0;
            padding:1.5em 0 0 1.5em;
            box-sizing: border-box;
            font-size: .66rem;
            letter-spacing: .6px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }
    
    .dahsboard-card.synchronism{
        overflow: hidden;
        width: 100%;

        @include desktop{
            max-width: 270px;
        }

        .content{
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: center;
        }
        .side{
            position: relative;
            float: left;
            width: 4.8em;
            height: 4.8em;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            border-radius:50%;
            margin: auto;
            box-shadow: 0 .2em .4em rgba(0,0,0,0.14);
            
            h4,label{
                position: relative;
                float: left;
                width: 100%;
                color: white;
                text-align: center;
            }
            label{font-weight: bold; text-transform:uppercase; font-size: .66rem; letter-spacing: .05em;}
            h4{
                font-size: 1.85rem;
                margin-bottom: .1em;
            }
        }

        .side.on{
            background:linear-gradient(35deg, $themeSuccess 0%, $themeSuccess2 100%);
            h4,label{color: white;}
        }

        .side.off{
            background:linear-gradient(-35deg, $themeError 0%, $themeError2 100%);
            h4,label{color: white;}
        }
    }

    .dahsboard-card.visits{
        position: relative;
        float: left;
        @include desktop{
            max-width: 350px;
        }

        .box-progress{
            display: flex;
            padding-right: 1em;
            flex-direction: column;
            justify-content: center;
            
            .component-progress{
                width: 100%;
                margin: 0 auto;
            }
            .progress-view{
                height: 6px;
                border-radius: 3px;
                background: rgba($color: $themeDark2, $alpha: 0.4);
                .progress{
                    border-radius: 3px;
                }
            }
            h4{
                width: auto;
                text-align: center;
                color: $themeDark;
                font-size: 3rem;
                margin: .1em auto;
            }


        }

        .box-progress, ul{
            flex-grow: 1;
            margin: auto;
            max-width: 200px;
        }

        .content{
            padding: 0 1em;
        }

        ul, li, h4{
            position: relative;
            float: left;
        }

        
        li.error{
            span{color: $themeError}
        }
        li{
            width: 100%;
            display: flex;
            padding: .06em 0;
            p, span{
                position: relative;
                float: left;
                height: auto;
                margin: auto;
                color: $themeDark2;
                font-size: .8rem;
                font-weight: 300;
                line-height: 1.2;
            }

            span{ 
                text-align: right; 
                margin-left: .5em; 
                font-weight: bold; 
                flex-grow: 2;
                color: rgba($color: $themeDark, $alpha:1);
            }
        }
    }

    .grid-state-users{
        position: relative;
        float: left;
        width: calc(100% - 2em);
        height: auto;
        padding: 1em;
        margin: 1em;
        box-sizing: border-box;
        border-radius: .5em;
        background: white;
        box-shadow: 0 .2em .4em rgba($color: $themeDark, $alpha: 0.1);

        h1{
            position: relative;
            float: left;
            width: 100%;
            font-size: 1.5rem;
            color: $theme;
            margin-bottom: 1em;
        }

        .ReactTable.grid-theme{
            position: relative;
            float: left;
            width: 100%;
            background: white;
            border: none;
            border-radius: .5em;
        }
    }
}
