#Home{
    @extend %flexStart;
    
    h1,h2,p{
        position: relative;
        float: left;
        width: 100%;
        text-align: center;
    }

    h1,h2{
        margin: 1em 0;
    }

    h1{
        font-size:4rem;
        margin: .1em 0;
        color: rgba($color: $themeDark, $alpha: 0.3);
    }

    h2{font-size: 1.5rem;}

    p{
        line-height: 1.2;
    }

    h2,p{
        color: rgba($color: $themeDark, $alpha: 0.75);
    }

    .container{
        position: relative;
        float: left;
        margin: auto;
        width: 400px;
        padding: 1em;
        box-sizing: border-box;
        top: -2.5em;
    }
}