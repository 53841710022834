#report{
    background: $themeBase;
    
    .content{
        position: relative;
        float: left;
        width: 100%;
        padding: 1em;
        box-sizing: border-box;
    }
    
    .panel{
        position: relative;
        float: left;
        width: 100%;
        padding: 1em;
        box-sizing: border-box;
        display: flex;
        h1{
            position: relative;
            float: left;
            color: $themeDark;
            font-size: 1.4rem;
            font-weight: 600;
            text-transform: uppercase;
            width: auto;
            margin: auto 0;
        }
       
        button{
            width: auto;
            float: left;
            margin-left: 1em;
        }
        
    }
    
    .form-report{
        position: absolute;
        top: 3em;
        left: calc(50% - 170px);
        width: 340px;
        padding: 2em 2em 3em 2em;
        box-sizing: border-box;
        border-radius: .2em;
        background: white;
        z-index: 2;
        opacity: 0;
        box-shadow: 0 .8em 1em rgba($color: $themeDark, $alpha: 0.1);
        @extend %trans;
        pointer-events: none;
        h3{
            position: relative;
            width: 100%;
            font-size: 1.2rem;
            margin:  0 0 1em 0;
            color: rgba($color: $themeDark, $alpha: 1.0)
        }
        
        form{
            position: relative;
            float: left;
            width: 100%;
        }

        .react-datepicker-wrapper, .select, .react-datepicker__input-container,   input[type="submit"]{
            position: relative;
            float: left;
            margin:.25em 0;
            width: 100%;
        }

        .select{
            margin:.3em 0;
        }

        
        .react-datepicker__input-container{
            input{
                position: relative;
                float: left;
                width: 100%;
                border: 1px solid rgba($themeDark, 0.25);
                padding: .6em .5em;
                box-sizing: border-box;
                border-radius: .2em;
                font-size: 1rem;
                background: white;
            }
        }

    }

    .form-report.on{
        top: 3em;
        pointer-events: all;
        opacity: 1;
    }
   
    #ReportList{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        padding: 0;
        box-sizing: border-box;


       
        ul, li{
            position: relative;
            float: left;
            width: 100%;
            padding: 1em;
        }

        ul{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        li{
            margin: 1em 1em 0 0;
            padding: 0;
            width: 100%;
            height: auto;

            @include desktop{
                width: 220px;
            }

            .contentli, a{
                display: flex;
                flex-wrap: wrap;    
                flex-direction: column;
                justify-content: flex-start;
                background-color: white;
                border-radius: 1em;
                box-shadow: 0 0.25em 0.5em rgba(49,49, 49, 0.03);
                border: 1px solid rgba(49, 49, 49, 0.1);
                height: 180px;
                padding: 1em;
                box-sizing:content-box;
                @extend %trans;
            }

            
            .contentli{
                border: 1px solid rgba(49, 49, 49, 0.1);
            }
            

            figure{
                position: relative;
                float: left;
                width: 40px;
                height: 40px;
                margin:auto;
                background: $themeBase;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                background-color: white;
                margin: .5em 0;
                overflow: hidden;
                img{
                    margin: auto;
                    height: auto;
                    width: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .text{
                position: relative;
                float: left;
                margin: 1em auto;
                width: 100%;
                p, label{
                    position: relative;
                    float: left;
                    font-size: .9rem;
                    width: 100%;
                    pointer-events: none;
                    font-weight: 500;
                    color: $themeDark;
                    @extend %trans;
                }
                label{
                    opacity: .6;
                    margin-top: .25em;
                    font-size: .8rem;
                }

                p.name{
                    font-size: .9rem;
                    color: $themeDark;
                    font-weight: bold;
                }


                p.get{
                    border: 1px solid rgba(#0aa35b, 0.2);
                    padding: .5em 1em;
                    box-sizing: border-box;
                    color: #0aa35b;
                    border-radius: 2em;
                    margin-top: 1em;
                    width: fit-content;
                }

            }


            &:hover{
                .text{
                    .get{
                        background-color:#0aa35b;
                        color:white;
                    }
                }

               a{ 
                   border: 1px solid rgba(#0aa35b, 0.2);
                   box-shadow: 0 1em 1em rgba($themeDark,0.075);
                   transform: translateY(-10px);
                }
            }
         
        }
    }
    
}