@mixin responsive($device) {

    @if $device == mobile {
        @media only screen and (min-width: 20.000em) and (max-width: 46.000em), screen and (min-width: 20.000em) and (max-width: 46.000em) and (orientation:portrait) {@content;}
    }

    @else if $device == desktop {
        @media only screen and (min-width: 46.001em)   { @content; }
    }

    @else if $device == full {
        @media only screen  and (min-width : 76.500em)  { @content; }
    }
}

@mixin responsiveManual($width) {
    @media only screen and (min-width : $width), screen and (min-width: $width) and (orientation:portrait) {@content;}
}

@mixin desktop{
    @media only screen and (min-width: 46.001em)   { @content; }
}

@mixin transition($second : 0.3s){
 will-change: transform;
 transition-property: all;
 transition-duration: $second;
 transition-timing-function: cubic-bezier(.55,0,.1,1);
}

@mixin trans{
 will-change: transform;
 transition-property: all;
 transition-duration: 0.15s;
 transition-timing-function: cubic-bezier(.55,0,.1,1);
}

@mixin flexGrow($size : 1){
    flex-grow: $size;
}

@mixin flexStretch(){
    display: flex;
    flex-direction: row;
    flex-wrap:nowrap;
    align-items: stretch;
}

@mixin flex_start(){
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

@mixin flexStart(){
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

@mixin flexColumn(){
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

/* New code here */

%trans{
   will-change: transform;
   transition-property: all;
   transition-duration: 0.2s;
   transition-timing-function: cubic-bezier(.55,0,.1,1);
}

%flexStart{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

%flexCenter{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
