#client{
    .content{
        padding: 1em;
        box-sizing: border-box;
        
        ul{
            position: relative;
            float: left;
            width: 100%;
            padding: .5em;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    
    .panel{
        position: relative;
        float: left;
        width: 100%;
        padding: 1em;
        box-sizing: border-box;
        
        h1{
            position: relative;
            float: left;
            color: $themeDark;
            font-size: 1.6rem;
            width: auto;
            margin-bottom: .5em;
        }
        
        .select-client{
            position: relative;
            float: right;
            width: 250px;
        }
        
        .search{display: none;}
        
        button{
            width: auto;
            float: left;
            margin-right: 1em;
        }
        
    }
    
    .kpis{
        position: relative;
        float: left;
        width: 100%;
        margin: 0 0 1em 0;
        display: flex;

        li{
            width:auto;
            margin: auto;
            box-sizing: border-box;
            padding: 1em;
            background: white;
            border-radius: .5em;
            box-shadow: 0 1em 1em rgba($color: $themeDark, $alpha: 0.1);
            min-width: 200px;
            border:1px solid #4f1d5830;

            h3{
                position: relative;
                float: left;
                color: $theme;
                width: 100%;
                margin-bottom: .65em;
                padding-bottom: .65em;
                border-bottom: 1px solid rgba($color: $theme, $alpha: 0.1);
            }

            p{
                font-size: .8rem;
                font-weight: bold;
                color: rgba($color: $themeDark, $alpha: 0.55);
                line-height: 1.4;

                span{
                    font-weight: normal;
                    font-size: 1rem;
                    color: $theme;
                }
            }
        }
    }
}