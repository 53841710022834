#createMenu{
    position: absolute;
    width: 380px;
    height: 100%;
    left: -380px;
    top: 0;
    background: white;
    z-index: 3;
    @extend %trans;

    .menu{
        position: relative;
        float: right;
        width: calc(100% - 64px);
        height: 100%;
        box-sizing: border-box;
        padding-top: 4em;
    }
    
    .actions{
        position: relative;
        float: left;
        width: 64px;
        height: 100%;
        box-sizing: border-box;
        padding-top: 2em;
        .back{
            position: relative;
            float: left;
            width: 26px;
            height: 26px;
            fill: $themeDark;
            padding: 5px;
            background: transparent;
            border-radius: 50%;
            margin: .1em 15px;
            border: 0px solid transparent;
            cursor: pointer;
            @extend %trans;
            
            &:hover{
                background: rgba($themeDark, 0.075);
            }
        }
        
      
    }

    ul,li{
        position: relative;
        float: left;
        width: 100%;
    }

    p.section-title{
        position: relative;
        float: left;
        width: 100%;
        font-size: .7rem;
        color: rgba($themeDark, 0.8);
        letter-spacing: .1px;
        text-transform: uppercase;
        margin: 2.5em 0 1em 0;
        font-weight: 600;
    }
    
    li{
        margin: .1em 0;
        width: calc(100% - .5em);
        box-sizing: border-box;
        border-radius: .2em;
        color:rgba($themeDark, 0.8);
        font-size: .9rem;
        cursor: pointer;
        line-height: 1.5;
        box-sizing: border-box;

        &:hover{
            background: rgba($themeDark, 0.07);
            a svg{ fill:$themeDark;}
            p{ color: $themeDark;}
        }
        
        a{
            position: relative;
            float: left;
            width: 100%;
            border-radius: .2em;
            padding: .5em .7em;
            box-sizing: border-box;
            letter-spacing: 1px;
            font-weight: 500;
            box-shadow: 0 0 0 rgba(0,0,0,0);
            @extend %trans;
            font-weight: bold;

            p{
                position: relative;
                float: right;
                width: calc(100% - 40px);
                line-height: 1.8;
                letter-spacing: .5px;
                font-size: .86rem;
                color: rgba($themeDark, 0.8);
                @extend %trans;
            }
            
            svg{
                position: relative;
                float: left;
                width: 24px;
                height: 24px;
                fill: rgba($themeDark, 0.8);
                background: transparent;
                border: 0px solid transparent;
                cursor: pointer;
                @extend %trans;
            }
        }
    }
    
}

#createMenu.on{
    left: 0px;
}