@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

$themeBase:#fafafa;
$themeDark:#313131;
$themeDark2:#999;
$theme:#8a05be;
$theme2:#6c1b93;
$theme3:#8a05be;

$sideMenu: #230b2d;

$themeError: #f44336;
$themeError2: #E53935;
$themeSuccess: #4CAF50;
$themeSuccess2: #388e3c;

$ExtContaAzul: #2687e9;

$font: 'Roboto', sans-serif;
