#ClientIO{

    .content{
        height: 100%;
        display: flex;
    }

    form{
        position: relative;
        float: left;
        width: 360px;
        height: auto;
        margin: auto;
        border:1px solid rgba($color: $themeDark, $alpha: 0.2);
        padding: 2em;
        box-sizing: border-box;
        border-radius: .2em;
        top: -4em;
        box-shadow: 0 1em 1em rgba($color: $themeDark, $alpha: 0.05);
        background: white;

        h1{
            position: relative;
            float: left;
            width: 100%;
            font-size: 1.4rem;
            margin-bottom:2em;
        }

        .input{
            position: relative;
            float: left;
            width: 100%;
            margin: .5em 0;
            button{width: auto;}
        }

       .button{
            width: 100%;
            margin-top: .5em;
        }

        .colors{
            position: relative;
            float: left;
            width: 100%;
            margin: .5em 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 1.4em;

        }

        .input-color{
            position: relative;
            float: left;
            width: auto;
            margin: 0 ;

            legend{
                position: relative;
                float: left;
                font-size: .76rem;
                font-weight: bold;
                color: $theme;
                line-height: 2;
                margin-right: 1em;
            }
        }
    }
}   