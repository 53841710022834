fieldset{
    padding: 0;
    width: 100%;

    legend{
        font-size: .76rem;
        font-weight: 500;
        color: $theme2;
        color: white;
        display: none;
    }

    input{
        width: 100%;
        border:none;
        padding: .75em 1em;
        box-sizing: border-box;
        background-color: white;
        border: none;
        border-radius: .2em;
        font-size: 1rem;
        color: $theme;
    }
}
