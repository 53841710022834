#integrations{

    background: $themeBase;

    .content{
        padding: 1em;
        box-sizing: border-box;

        ul{
            position: relative;
            float: left;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .panel{
        position: relative;
        float: left;
        width: 100%;
        padding: 1em;
        box-sizing: border-box;

        h1{
            position: relative;
            float: left;
            color: $themeDark;
            font-size: 1.6rem;
            width: auto;
            margin-bottom: .5em;
        }

        .select-client{
            position: relative;
            float: right;
            width: 250px;
        }

        .search{display: none;}

        button{
            width: auto;
            float: left;
            margin-right: 1em;
        }
        
    }
    
    ul{
        position: relative;
        float: left;
        width: 100%;
        margin: 1em;

        li{
            position: relative;
            float: left;
            margin: 0 2em 2em 0;
            width: 400px;
            height: auto;
            padding: 1.5em;
            border-radius: .2em;
            box-sizing: border-box;
            box-shadow: 0 .5em 1em rgba(#000000, 0.1);
            background: white;
            .text{
                position: relative;
                float: right;
                width: calc(100% - 110px);
                height: auto;

                p{
                    color: $themeDark;
                    font-size: 1.1rem;
                    font-weight: bold;
                }
                p.description{
                    font-size: .84rem;
                    line-height: 1.4;
                    font-weight: normal;
                    margin: .5em 0 1em 0;
                }

                a{
                    position: relative;
                    float: left;
                    border-radius: 1em;
                    color: white;
                    font-size: .84rem;
                    padding: .42em 1.2em; 
                    background:rgba($themeDark, 0.35);
                    @extend %trans;
                    opacity: 0;
                    &:hover{
                        background: $themeSuccess;
                    }
                }
            }
            img{
                position: relative;
                float: left;
                width: 90px;
                height: auto;
                border-radius: .2em;
            }
        }
    }
}

#contaAzul{

    .integration-block{
        position: relative;
        float: left;
        width: 60%;
        margin: 3em 20%;
        border-radius: .5em;
        box-shadow: 0 .8em 1em rgba($themeDark, 0.1);
        overflow: hidden;
    }

    .header-integration{
        position: relative;
        float: left;
        width: 100%;
        background: white;
        display: flex;
        padding: 1em 0;
        box-sizing: border-box;
        border-bottom: 2px solid rgba($themeDark, 0.05);
        img{
            position: relative;
            float: left;
            width: 100px;
            height: auto;
            border-radius: .5em;
        }

        p{
            position: relative;
            float: left;
            color: $themeDark;
            font-size: 1rem;
        }

        button{
            position: relative;
            float: left;
            width: 120px;
        }

        img, p, button{
            margin: auto;
        }

    }

    .flow{
        position: relative;
        float: left;
        width: 100%;
        display: flex;
        background: white;
        border-bottom: 1px solid rgba($themeDark, 0.05);
        padding-top: 1.5em;

        label{
            position: absolute;
            width: 100%;
            top: 0;
            padding: .5em;
            box-sizing: border-box;
            height: auto;
            background: rgba($themeBase, 0.5);
            color: rgba($themeDark, 0.7);
            font-size: .82rem;
            font-weight: bold;
            text-align: center;
        }

        ul{
            position: relative;
            float: left;
            margin: auto;
            width: 120px;
        
            li{
                position: relative;
                float: left;
                width: 100%;
                background: white;
                padding: .5em 0;
                border-radius: .25em;

                svg{
                    position: relative;
                    float: left;
                    width: 24px;
                    height: 24px;
                    fill:$ExtContaAzul;
                    margin-right: 6px;
                }
                p{
                    position: relative;
                    float: left;
                    font-size: .9rem;
                    line-height: 1.4;
                    font-weight: bold;
                    color: $ExtContaAzul;
                }
            }
        }


        img.logo{
            position: relative;
            float: left;
            width: 100px;
            height: auto;
        }

        svg.arrow{
            position: relative;
            float: left;
            margin: auto;
            fill:rgba($themeDark, 0.5);
            width: 36px;
            height: auto;
        }

        ul, img.logo {
            margin: auto;
        }

    }
}