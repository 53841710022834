html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, tfoot, thead, tbody, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

table{
  vertical-align: baseline;
  border-spacing: 0px;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a:link, a:visited { text-decoration: none}

button{
  cursor: pointer;
}

select {
  -webkit-appearance:none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance:none;
  text-indent: 0.01px;
  text-overflow: '';

  label{
    display: none;
  }

}

input[type='submit']{
  cursor: pointer;
}

[draggable] {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
textarea{resize: none;}

*{
  &:focus {outline:none;}
}

textarea,
input[type="text"],
input[type="button"],
input[type="submit"], button {
 -webkit-appearance: none;
 -moz-appearance: none;
 appearance: none;
 border-radius: 0;
}

/*Setup Browser*/
input,select{
  background-color: transparent;
  -webkit-text-fill-background: transparent !important;
  text-fill-background: transparent !important;
}

input:-webkit-autofill,select:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

@keyframes autofill {
  to { color: #666; background: transparent; }
}
input:autofill, select:autofill {
  background-color: transparent !important;
  box-shadow: 0 0 0 1000px #fff inset;
  animation-name: autofill;
  animation-fill-mode: both;
}


a, p, h1,h2,h3,h4,h5,h6, span, label, input, textarea,
button, ul, ol, table,  form, input, select, legend {font-family: $font;}




