.environment-card{
    position: relative;
    float: left;
    width: 260px;
    height: auto;
    background: white;
    border-radius: .2em;
    margin: 1em auto;
    @extend %trans;
    cursor: pointer;
    overflow: hidden;
    box-shadow: 0 .25em .5em rgba($themeDark,0.025);
    border:1px solid rgba($themeDark,0.1);

    .inative{display: none;}

    ul.counters{
        position: relative;
        float: left;
        width: 100%;
        flex-wrap: nowrap;
        display: flex;

        li{
            position: relative;
            float: left;
            width: auto;
            flex-grow:1;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            @extend %trans;

            .component-progress{
                width: 50%;
                height: 4px;
                margin: .25em 25%;
                border-radius: 2px;
                .progress-view{
                    height: 100%;
                    border-radius: 2px;
                }
            }

            label, p{
                position: relative;
                float: left;
                color: rgba($themeDark, 0.7);
                font-size: .7rem;
                text-align: center;
                margin: .2em 0;

                span{
                    margin: 0 .2em;
                }
            }

            label{
                color: rgba($themeDark, 0.3);
                font-size: .56rem;
                text-transform: uppercase;
                font-weight: bold;
            }

            p{font-weight: bold;}
        }

        li.disable{
            display: none;
        }
    }
    
    p.name{
        position: relative;
        float: left;
        width: 100%;
        padding: 2.5em 0 1.5em 0;
        box-sizing: border-box;
        text-align: center;
        font-size: .8rem;
        color: rgba($themeDark, 1.0);
    }



    .analytics{
        position: relative;
        float: left;
        width: 100%;
        height: auto;
        padding: .7em 0;
    }

    figure{
        position: relative;
        float: left;
        width: 100%;
        height: 90px;
        box-sizing: border-box;
        padding: 0 1em;
        display: flex;
        flex-direction: column;
        img{
            position: relative;
            float: left;
            width: auto;
            height: auto;
            margin: auto;
            max-height: 100%;
            max-width: 100%;
            @extend %trans;
        }
    }

    .user-status{
        position: relative;
        float: left;
        width: 60%;
        margin: 1em 20%;
        padding: 1em 0 0 0;
        box-sizing: border-box;
   
        .component-progress{
            display: block;
            .progress-view{
                width: 100%;
            }
            span{
                position: relative;
                float: left;
                width: 100%;
                margin: .5em 0;
                font-size: 1.2em;
                font-weight: 500;
                text-align: center;
                color: rgba($themeDark, 1);
            }
        }
    }

    a.link{
        position: absolute;
        right: -3em;
        top: -3em;
        margin:0;
        padding: .2em .3em;
        border-radius: 0 0 0 .75em;
        background:rgba($theme, 0.05);
        @extend %trans;
        color: $theme;

        svg{
            position: relative;
            float: left;
            width: 24px;
            height: 24px;
            fill: $theme;
        }
    }

    &:hover{
        box-shadow: 0 1em 1em rgba($themeDark,0.075);
        transform: translateY(-10px);

        a.link{
            top: 0;
            right: -2px;
        }

        figure img{
            @include zoom(.85);
        }

        p.name{ color: rgba($theme, 1); }

        .analytics{
            background: white;
        }

    }

}

.environment-card.done{
    border:2px solid $themeSuccess;
    .component-progress {
        .progress-view .progress {background: $themeSuccess;}
        p{font-weight: bold; color: $themeSuccess; }
    }
    .analytics{
        p,label{color: $themeSuccess;}
        label{opacity: 1;}
    }
}