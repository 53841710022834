body{
    background: $themeBase;
}

.pg-full{
    position: absolute;
    width: calc(100% - 300px);
    height: 100%;
    left: 300px;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
}

.pg{
    position: relative;
    float: left;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.app{
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
}

.app{
    .main, #sideMenu{
        position: relative;
        float: left;
        margin: auto;
        height: 100vh;
        width: 100%;
    }

    #sideMenu{
        position: absolute;
        width: 100%;
        margin: auto;

        @include desktop{
            position: relative;
            float: left;
            width: 320px;
        }
    }

    #sideMenu.compact{
        position: relative;
        float: left;
        width: 5em;
        @include desktop{
            width: 5em;
        }
    }

}

.pg-full{
    width: 100vw;
    left: 0;
}


.select, .select-client{
    font-family: $font;
}

.bk-main, .bk, .bk-content{
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 2;
    background: rgba(0,0,0,0);
    opacity: 0;
    pointer-events: none;
    @extend %trans;
    cursor: pointer;
}


.bk-main.on, .bk.on{
    pointer-events: all;
    opacity:.92;
    background: rgba($color: $theme, $alpha: 0.92)
}
.bk-content.on{
    pointer-events: all;
    background:$themeBase;
    z-index: 1;
    opacity:.92;
}

.business-form, .device-form{
    position: absolute;
    width: 300px;
    height: auto;
    margin: 4em calc(50% - 150px);
    z-index: 2;
    background: white;
    padding: 1em;
    box-sizing: border-box;
    border-radius: .2em;
    display: none;

    form{
        position: relative;
        float: left;
        width: 100%;
    }
    
    header{
        position: relative;
        float: left;
        width: 100%;
        margin-bottom: 1.25em;

        h4{
            position: relative;
            float: left;
            font-weight: bold;
            color: $themeDark;
        }

        button{
            width: auto;
            float: right;
            margin:0;
            padding: 0 .7em;
            text-align: center;
            background: none;
            box-shadow: none;
            color: $themeDark;
            font-weight: bold;
            border: none;
            font-size: 1rem;
        }
    }

    .component-input{
        margin-bottom: 1em;
    }
}
.business-form.on, .device-form.on{
    display: block;
}

.device-form{
    .select{
        margin-bottom: 1em;
    }
}
.business-form form p{
    position: relative;
    float: left;
    width: 100%;
    margin: .5em 0 1em 0;
}

.ReactTable{
    font-family: $font;
}

.pg-loading.on{
    background:rgba(white,0.92);
    pointer-events: auto;
}
.pg-loading{
    position:absolute;
    width:100%;
    height:100%;
    background:rgba(white,0);
    top:0;
    left:0;
    right:0;
    bottom:0;
    @extend %trans;
    display: flex;
    pointer-events: none;
    .loading-content{
        position: relative;
        margin:auto;
        width: auto;
        div{
            margin:0px;
            box-sizing: content-box;
        }
    }
    
}