#ManagerIO{
    .content{
        height: 100%;
        display: flex;
    }

    form{
        position: relative;
        float: left;
        width: 600px;
        height: auto;
        margin: auto;
        border:1px solid rgba($color: $themeDark, $alpha: 0.2);
        padding: 2em;
        box-sizing: border-box;
        border-radius: .5em;
        box-shadow: 0 1em 1em rgba($color: $themeDark, $alpha: 0.05);

        h1{
            position: relative;
            float: left;
            width: 100%;
            font-size: 1.4rem;
            margin-bottom:1em;
        }

        .component-input, .select-client{
            position: relative;
            float: left;
            margin: .8em 0;
        }
        
        .select-client{
            width: 100%;
        }

        input[type='submit']{
            width: auto;
            float: right;
            margin-top: 1em;
        }

     
    }
}