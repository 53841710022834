.pager{
    position: relative;
    float: left;
    height: auto;
    @extend %flexStart; 
    
    li{
        position: relative;
        float: left;
        width: 32px;
        height: 32px;
        border-radius: .2em;
        border:1px solid rgba(0,0,0,0.1);
        margin: auto;
        text-align: center;
        line-height: 2;
        cursor: pointer;
        &:hover{
            background: rgba(255,255,255, 0.25);
        }
    }    

    li.on{
        background: white;
        font-weight: bold;
        border:1px solid white;
    }

    li.tools{
        width: auto;
        padding: 0 .5em;
    }
}