.component-progress{
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: stretch;

    p{
        position: relative;
        float: left;
        width: auto;
        margin: auto;
        margin-left: 1em;
        font-size: 12px;
        font-weight: bold;
        text-align: center; 
        line-height: 1.1;       
        font-weight: bold;
        color: rgba($color: $themeDark, $alpha: 0.8);
    }

    .progress-view{
        position: relative;
        float: left;
        width: auto;
        margin: auto;
        height: 4px;
        border-radius:2px;
        background: rgba($color: $themeDark, $alpha: 0.125);
        flex-grow: 2;

        .progress{
            position: relative;
            float: left;
            height: 100%;
            border-radius:2px;
            background: $theme;
            background: linear-gradient(264deg, rgba(228,0,212,1) 0%, $theme 100%);
        }
    }
}

              