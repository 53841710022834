.cadastre{
    @extend %flexCenter;
    background: linear-gradient(45deg, $theme, $themeDark);
    
    form{
        position: relative;
        width: 100%;
        padding: 2em 4em;
        box-sizing: border-box;
        margin: auto 2em;
        background-color:white;
        border: 1px solid rgba($color: $themeDark, $alpha: 0.2);
        border-radius: .2em;

        @extend %trans;

        .logo{
            position: relative;
            margin-bottom: 1em;
            width: 100%;
        }

        &:hover{
            border-radius: 0;
        }

        fieldset{
            margin-bottom: 1.5em;
        }

        .links{
            position: relative;
            width: 100%;
            margin-top:1em;
            a{
                color: $theme2;
                font-size: .9rem;
                text-decoration: underline;
            }
        }
        
    }

    @include desktop{
        form{
            position: relative;
            width: 40%;
            padding: 2em;
            margin: auto;
        }    

        input[type="submit"]{
            float: right;
            width: auto;
        }
    }
}