#EnvironmentsIO {
    
    .content{
        height: 100%;
        display: flex;
    }

    form{
        position: relative;
        float: left;
        width: 400px;
        height: auto;
        margin: auto;
        border:1px solid rgba($color: $themeDark, $alpha: 0.2);
        padding: 2em;
        box-sizing: border-box;
        border-radius: .2em;
        box-shadow: 0 1em 1em rgba($color: $themeDark, $alpha: 0.05);
        background: white;

        h1{
            position: relative;
            float: left;
            width: 100%;
            font-size: 1.4rem;
            margin-bottom:1em;
        }

        .input, .select-client, .button {
            position: relative;
            float: left;
            width: 100%;
            margin: .5em 0;
        }

    }
}