#environments{
    background: $themeBase;

    .content{
        box-sizing: border-box;

        ul{
            position: relative;
            float: left;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .environments-list{
            padding: 1em;
        }
    }

    

    
}