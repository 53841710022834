.search{
    position: relative;
    float: left;
    border: 1px solid rgba($themeDark, 0.25);
    background: white;
    border-radius: .2em;
    
    input{
        border: 0px solid transparent;
        font-size: 1rem;
        padding: .4em 0 .5em 2.3em;
    }
    
    svg{
        position: absolute;
        top: calc(50% - 12px);
        left: 6px;
        fill:$theme;
        width: 24px;
        height: 24px;
        opacity: .25;
    }
}