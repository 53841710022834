@mixin zoom($zoom) {
    transform:scale($zoom);
  }
  
  @mixin rotate($angle) {
    transform: rotate($angle);
  }
  
  @mixin keyframes ($name) {
    @-webkit-keyframes $name {
      @content;
    }
  
    @-moz-keyframes $name {
      @content;
    }
  
    @-o-keyframes $name {
      @content;
    }
  
    @keyframes $name {
      @content;
    }
  }
  
  @mixin animation($animation, $duration, $count : infinite, $effect : ease-out, $delay : 0s){
    animation: $animation $duration $effect $delay $count;
  }
  
  @mixin bgUrl($url) {
    background-image: url($url);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  
  %trans{
     will-change: transform;
     transition-property: all;
     transition-duration: 0.15s;
     transition-timing-function: cubic-bezier(.55,0,.1,1);
  }
  
  %zoomIn{
    transform:scale(1);
  }
  %zoomOut{
    transform:scale(0);
  }
  